import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PageSplash from "../../components/PageSplash/PageSplash"
import { graphql, useStaticQuery } from "gatsby"

import { ValueProp, ValuePropHeader } from "../../components/ValueProp/ValueProp"
import IconRow from "../../components/IconRow/IconRow"
import IconRowItem from "../../components/IconRow/IconRowItem"

import LoginIcon from "../../images/icons/log-in.inline.svg"
import PieIcon from "../../images/icons/pie.inline.svg"
import ChevRightIcon from "../../images/icons/chev-right.inline.svg"
import MaximizeIcon from "../../images/icons/maximize.inline.svg"

import ScissorIcon from "../../images/icons/scissor.inline.svg"
import LocationIcon from "../../images/icons/location.inline.svg"
import Icon247 from "../../images/icons/24-7.inline.svg"
import FeatherIcon from "../../images/icons/feather.inline.svg"
import CoffeeIcon from "../../images/icons/coffee.inline.svg"

import AwardIcon from "../../images/icons/award.inline.svg"
import GridIcon from "../../images/icons/grid.inline.svg"
import LayersIcon from "../../images/icons/layers.inline.svg"
import ThumbsUpIcon from "../../images/icons/thumbs-up.inline.svg"
import TabletIcon from "../../images/icons/tablet.inline.svg"
import BarChartIcon from "../../images/icons/bar-chart-alt.inline.svg"
import TrendingUpIcon from "../../images/icons/trending-up.inline.svg"
import SettingsIcon from "../../images/icons/settings.inline.svg"
import SmileIcon from "../../images/icons/smile.inline.svg"
import UsersIcon from "../../images/icons/users.inline.svg"

import TrainingScreenshotDe from "../../images/pages/products/training/screenshot-de"
import ScreenshotStatisticImage from "../../images/pages/products/training/screenshot-statistic-de.svg"

import Chart from "../../images/pages/products/training/chart-de.inline.svg"
import Knowledge from "../../images/pages/products/training/knowledge.inline.svg"

import Style from "./training.module.css"
import PreHeading from "../../components/PreHeading"
import HighlightSection from "../../components/HighlightSection"
import { StepperItem } from "../../components/Stepper/Stepper"
import CustomerSlider from "../../components/CustomerSlider"

import { MediaAndText } from "../../components/ImageAndText/ImageAndText"
import { StartNowDe } from "../../components/StartNow"
import { SupportDe } from "../../components/Support"
import { PageType } from "../../index"
import ProductNav from "../../components/ProductNav/ProductNav"

const TrainingDe: PageType = ({ location }) => {
    const {
        splash,
    } = useStaticQuery(graphql`
        query TrainingDe {
            splash: file(relativePath: { eq: "pages/products/training/splash.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 680) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return <Layout location={location}>
        <Seo
            title="Wissen nachhaltig sichern"
            description="Erfolgreiche Implementierung von Wissensmanagement, um nachhaltig Wissenstransfer sicherzustellen. Was ist Wissensmanagement? Wo findet Wissensmanagement statt? Warum ist Wissensmanagement wichtig?"
            keywords="Wissensmanagement, Wissenstransfer, Nachhaltigkeit, Implementierung"
        />
        <ProductNav />
        <PageSplash
            preHeading="Digitales Wissensmanagement"
            heading="Wissen nachhaltig sichern"
            cta="Mehr erfahren"

            image={splash}
            imageFit={{
                objectPosition: "top right"
            }}
        />
        <span aria-hidden id="content" />
        <ValueProp>
            <ValuePropHeader
                preHeading="Schulungswissen"
                heading="Schaffen Sie spielend Wissen, das bleibt"
            >
                <p>
                    Mit QUADIO lernen heißt Wissen messen und dauerhaft behalten. Die Lernforschung hat erkannt: Wer spielerisch lernt, lernt nachhaltiger. QUADIO bietet Ihnen digitale Trainings-Module, die Vorwissen und Lerntempo berücksichtigen, dosiert konzentriertes Fachwissen vermitteln, per Sofort-Feedback schnelle Erfolgserlebnisse bringen und individuelle Lernfortschritte mess- und überprüfbar machen.
                </p>
            </ValuePropHeader>

            <IconRow>
                <IconRowItem icon={<LoginIcon />}>
                    Effektive Onboardings
                </IconRowItem>
                <IconRowItem icon={<ChevRightIcon />}>
                    Schneller Wissenstransfer
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Abteilungsspezifische Analysen
                </IconRowItem>
                <IconRowItem icon={<MaximizeIcon />}>
                    Einfach skalierbar
                </IconRowItem>
            </IconRow>

            <TrainingScreenshotDe className="max-w-full m-auto" />
        </ValueProp>
        <section className="section-x-margin">
            <div aria-hidden hidden className="block mobile:hidden overflow-hidden">
                <Chart className={Style.chart} />
            </div>
            <div className={`flex mobile:flex-wrap py-32 mobile:py-0 ${Style.blueGradient}`}>
                <div className="flex w-2/3 sm_desktop:w-3/5 mobile:w-full sm_desktop:flex-wrap">
                    <div className="w-1/2 sm_desktop:w-full px-16 pl-32 sm_desktop:pl-16 tablet:px-8">
                        <PreHeading>Schulungswissen</PreHeading>
                        <h2>Return On Learning Invest</h2>
                    </div>
                    <div className="w-1/2 sm_desktop:w-full px-16 tablet:px-8">
                        <p>
                            Studien zur gängigen Schulungspraxis zeigen: Schon kurz nach einem Seminar oder Workshop sind nur noch 20 Prozent der Inhalte präsent. Was im Detail gelernt wird, ist oft wenig transparent, individuelle Lernzuwächse kaum belegbar. Mit Quadio wird der Return On Learning Invest messbar gesteigert, da das Wissen durch Spaß und Wiederholung nachweislich in den Köpfen verankert wird.  Das Ergebnis: Trainings, die sich auszahlen.
                        </p>
                    </div>
                </div>
                <div className="w-1/3 sm_desktop:w-2/5 mobile:w-full px-16 tablet:px-8">
                    <Knowledge className="m-auto w-full max-w-md max-h-full m-auto" />
                </div>
            </div>
        </section>
        <HighlightSection
            stepperItems={<>
                <StepperItem
                    heading="Digital & Papierlos"
                    icon={<ScissorIcon />}
                >
                    Ihre Werte, Ihre Vision, Ihre Unternehmenskultur und Ihr Design. Damit sich Ihre Mitarbeiter mit Ihrem Unternehmen identifizieren können.
                </StepperItem>
                <StepperItem
                    heading="Einfache und schnelle Administration"
                    icon={<LocationIcon />}
                />
                <StepperItem
                    heading="Überall aufrufbar"
                    icon={<Icon247 />}
                />
                <StepperItem
                    heading="Einfache Aufbereitung"
                    icon={<CoffeeIcon />}
                />
                <StepperItem
                    heading="Unterstützung bei Inhalten"
                    icon={<FeatherIcon />}
                />
            </>}
        >
            <div className="my-auto">
                <PreHeading>Daten Digital</PreHeading>
                <h2>Digitalisierung des Wissens-Managements</h2>
                <p>
                    Nicht mehr an Papier und Dateisystemen gebunden sein, sondern jederzeit überall abrufbar, skalierbare unternehmensweite Aktivitäten (Wettbewerbe, Kampagnen und Incentives) sowie einfache und mühelose Verwaltung von Mitarbeiterprofilen.
                </p>
            </div>
        </HighlightSection>
        <section className="section-x-margin">
            <MediaAndText
                cutOff
                noImageLimit
                contentSide={{
                    desktop: "right",
                    mobile: "bottom"
                }}
                image={<img alt="" src={ScreenshotStatisticImage} className="tablet:section-x-padding w-full pointer-events-none" />}
            >
                <PreHeading>Echtzeit Analysen</PreHeading>
                <h2>Wissen nachweisen</h2>
                <p>
                    Statt auf den Erfolg von Schulungen und Onboardings zu hoffen, hilft Quadio das Wissen transparent zu analysieren. So erhalten Sie Übersicht über nicht sichergestelltes Wissen und können auf Basis von Echtzeitanalysen unternehmensrelevante Entscheidungen treffen. So finden Sie Wissenslücken in Ihrem Unternehmen und bauen diese mithilfe unserer neurowissenschaftlichen Methoden effektiv aus.
                </p>
            </MediaAndText>
        </section>
        <SupportDe>
            <li>Erstellung von unternehmensspezifischen Trainings</li>
            <li>Projektbegleitung und Strategiebegleitung</li>
            <li>Corporate Design</li>
            <li>Projektbegleitung und -beratung</li>
            <li>Technischer Support</li>
        </SupportDe>
        <section className="section-x-margin section-x-padding">
            <PreHeading>Overview</PreHeading>
            <h2>Features</h2>

            <IconRow className="mt-16 mobile:mt-12" fluid>
                <IconRowItem icon={<GridIcon />}>
                    Trainings-Modul nach Schulungen
                </IconRowItem>
                <IconRowItem icon={<LayersIcon />}>
                    Einsicht von Schulungsunterlagen
                </IconRowItem>
                <IconRowItem icon={<ThumbsUpIcon />}>
                    Bewertung von Trainings
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Statistiken
                </IconRowItem>
                <IconRowItem icon={<TabletIcon />}>
                    Von überall und jederzeit
                </IconRowItem>
                <IconRowItem icon={<BarChartIcon />}>
                    Gewinnspiele für kurzzeitige Steigerung des Traffics
                </IconRowItem>
                <IconRowItem icon={<TrendingUpIcon />}>
                    Live Daten und Analysen
                </IconRowItem>
                <IconRowItem icon={<UsersIcon />}>
                    Benutzerverwaltung
                </IconRowItem>
                <IconRowItem icon={<SettingsIcon />}>
                    Trainingsverwaltung
                </IconRowItem>
                <IconRowItem icon={<FeatherIcon />}>
                    Content Management System
                </IconRowItem>
                <IconRowItem icon={<SmileIcon />}>
                    Gamification und Motivation
                </IconRowItem>
                <IconRowItem icon={<AwardIcon />}>
                    Automatisierte Zertifikate
                </IconRowItem>
            </IconRow>
        </section>
        <section className="section-x-margin">
            <CustomerSlider />
        </section>
        <StartNowDe />
    </Layout>
}

export default TrainingDe
